@import url('https://fonts.googleapis.com/css2?family=Khula:wght@300;600;800&family=Lato:wght@300&display=swap');

html, body, #root {
    font-family: 'Lato', sans-serif;
    width: 100%;
    height: 100%;
    background: #AA076B;
    background: -webkit-linear-gradient(to right, #61045F, #AA076B);
    background: linear-gradient(to right, #61045F, #AA076B);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff;
}

.search {
    max-width: 80%;
    width: 400px;
    height: 50px;
    margin-top: 0;
    transition: all .25s linear;
    /* padding: 50px;
    border-radius: 3px;
    box-shadow: 1px 1px 40px #61045F;
    background: #e1e1e1;
    background: -webkit-linear-gradient(to right, #ffffff, #e1e1e1);
    background: linear-gradient(to right, #ffffff, #e1e1e1); */
}

.search.hasResults {
  height: 500px;
  margin-top: 50px;
}

.search > input {
    width: 100%;
    border: 1px solid #e1e1e1;
    border-radius: 3px;
    line-height: 40px;
    color: #AA076B;
    text-indent: 15px;
}

.search > input:focus {
    outline: 3px solid #AA076B;
}

.searchResults {
  list-style: none;
  margin: 20px 0 0;
  padding: 0;
  max-height: 450px;
  overflow-y: auto;
  opacity: 1;
  transition: all .25s linear;
}

.searchResults:empty {
  opacity: 0;
  max-height: 50px;
}

.searchResults > li {
  padding: 10px 0;
  border-top: 1px solid rgba(255, 255, 255, .25);
}
.searchResults > li:first-child {
  border-top: 0;
}

.searchResults > li > a {
  color: #fff;
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.searchResults > li > a > img {
  width: 50px;
  height: 50px;
  display: block;
  margin-right: 20px;
  border-radius: 25px;
  object-fit: cover;
}

.series-episodes-container {
  position: fixed;
  width: 300px;
  height: 100%;
  top: 0;
  left: 0;
  margin-top: 60px;
  overflow: hidden;
  list-style: none;
  border-right: 1px solid rgba(255, 255, 255,.1);
  box-shadow: 1px 1px 20px rgba(0, 0, 0, .15);
}

.series-navbar {
  position: fixed;
  width: 300px;
  height: 60px;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  border-right: 1px solid rgba(255, 255, 255,.1);
  box-shadow: 1px 1px 20px rgba(0, 0, 0, .15);
}

.series-episode-toggle {
  font-size: 1.5rem;
  padding: 20px;
}

.series-episodes {
  position: absolute;
  top: 0;
  left: 0;
  right: -17px;
  bottom: 0;
  overflow-y: scroll;
  list-style: none;
}

.series-episodes > li {
  padding: 15px 20px 15px 0;
}
.series-episodes > li > a {
  color: #fff;
  text-decoration: none;
}

.series-content {
  margin-left: 300px;
}
.player {
  aspect-ratio: 16 / 9;
  max-width: 1280px;
  width: auto;
  border: 0;
  background-color: #000;
  box-shadow: 1px 1px 30px rgba(0, 0, 0, .35);
}
.player > video {
  width: 100%;
  height: 100%;
}


@media screen and (max-width: 700px) {
  .series-navbar {
    width: 100%;
    height: 60px;
  }

  .series .series-episodes-container {
    width: 100%;
    background-color: rgba(0, 0, 0, .45);
    backdrop-filter: blur(30px);
    z-index: 9999;
  }
  .series.now-playing .series-episodes-container {
    display: none;
  }
  .series.now-playing .series-episodes-container.open {
    display: block;
  }
  .series-content {
    margin-left: 0;
  }
}

button {
    color: #fff;
    background-color: #AA076B;
    border: 0;
    border-radius: 3px;
    line-height: 40px;
    text-align: center;
    padding: 0;
    margin: 10px 5px 5px 0;
    width: 100px;
    height: 40px;
    overflow: hidden;
    white-space: nowrap;
    transform: translateY(0);
    transition: all .25s linear;
}

button.hidden {
    width: 0;
    margin-right: 0;
}
button:hover {
    transform: translateY(3px);
    background-color: #61045F;
    cursor: pointer;
}

.search > input + div {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    height: 0;
    overflow: hidden;
    opacity: 0;
    transition: all .25s linear;
}

.search > input:focus + div, .search:hover > input + div {
    opacity: 1;
    height: 50px;
}

.search + div {
    width: 400px;
    padding: 10px 0;
    text-align: left;
    z-index: -1;
    opacity: 1;
    transition: opacity .25s linear;
}

.search.focused + div, .search:hover + div {
    opacity: 0;
}